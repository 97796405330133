.MainMenuCon1A {
  position: absolute; /* Inherits layering behavior */
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  display: flex; /* Flexbox layout */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  gap: 24px; /* Space between children */
  padding-right: 64px;
  overflow: hidden; /* Prevent horizontal overflow */
  /* overflow-y: scroll; Enable vertical scrolling */
}
.MainMenuCon1 {
  position: relative;
  display: flex;
  padding-right: 64px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  background: transparent;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
}
.MainMenuCon20 {
  position: relative;
  left: -100%;
  display: flex;
  padding: 24px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  background: var(--app-gray-soft, #e0e0e0);
}
.MainMenuCon2B {
  position: relative;
  left: -100%; /* Start off-screen */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  background: var(--app-gray-soft, #e0e0e0);
  transition: left 0.3s ease-in-out; /* Smooth transition */
  max-width: 500px;
}
.MainMenuCon2 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 24px 12px;
  align-self: stretch;
  overflow: hidden; /* Prevent horizontal overflow */
  overflow-y: scroll;
}
#ButtonCloseMenu {
  position: absolute;
  top: 6px;
  right: 6px;
  padding: 6px;
  border-radius: 6px;
  color: #fff;
  /* left: 50%; */
  /* transform: translateX(-50%); */
  background: #ffffff5f;
  /* z-index: 1; */
}

.MainMenuCon2Open {
  left: 0; /* Slide in */
}

.MainMenuCon3 {
  display: flex;
  padding: 6px;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--app-white, #fff);
}
.ProfileOpenFotoConBForMenu {
  width: 48px;
  height: 48px;
  border-radius: 50px;
  border: solid 1px var(--app-gray-medium);
  background: var(--app-gray-soft);
  overflow: hidden;

  fill: #fafafa;
  stroke-width: 1px;
  stroke: var(--app-gray-medium, #6d6d6d);
}
.MainMenuCon4 {
  display: flex;
  padding: 2px 0px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}
.MainMenuProfileName {
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 85.714% */
}
.MainMenuProfileEmail {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
}
.MainMenuCon5 {
  display: flex;
  padding: 12px 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 15px;
  background: #fff;
}
.MainMenuCon6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.MainMenuCon7 {
  display: flex;
  padding: 0px 6px;
  align-items: flex-end;
  gap: 12px;
  align-self: stretch;
}
.MainMenuOptionText {
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 75% */
}
.MainMenuOptionSeperator {
  height: 2px;
  align-self: stretch;
  background: var(--app-gray-soft, #e0e0e0);
}
