.MainMenuCon1A {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: 100%;
  padding-right: 64px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.MainMenuCon1 {
  background: #00000080;
  flex: 1 0 0;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  height: 100%;
  padding-right: 64px;
  display: flex;
  position: relative;
}

.MainMenuCon20 {
  background: var(--app-gray-soft, #e0e0e0);
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  padding: 24px 12px;
  display: flex;
  position: relative;
  left: -100%;
}

.MainMenuCon2B {
  background: var(--app-gray-soft, #e0e0e0);
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  align-items: flex-start;
  max-width: 500px;
  transition: left .3s ease-in-out;
  display: flex;
  position: relative;
  left: -100%;
}

.MainMenuCon2 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  padding: 24px 12px;
  display: flex;
  position: relative;
  overflow: hidden scroll;
}

#ButtonCloseMenu {
  color: #fff;
  background: #ffffff5f;
  border-radius: 6px;
  padding: 6px;
  position: absolute;
  top: 6px;
  right: 6px;
}

.MainMenuCon2Open {
  left: 0;
}

.MainMenuCon3 {
  background: var(--app-white, #fff);
  border-radius: 12px;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  padding: 6px;
  display: flex;
}

.ProfileOpenFotoConBForMenu {
  border: solid 1px var(--app-gray-medium);
  background: var(--app-gray-soft);
  fill: #fafafa;
  stroke-width: 1px;
  width: 48px;
  height: 48px;
  stroke: var(--app-gray-medium, #6d6d6d);
  border-radius: 50px;
  overflow: hidden;
}

.MainMenuCon4 {
  flex-direction: column;
  flex: 1 0 0;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  padding: 2px 0;
  display: flex;
}

.MainMenuProfileName {
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
}

.MainMenuProfileEmail {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}

.MainMenuCon5 {
  background: #fff;
  border-radius: 15px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  padding: 12px 6px;
  display: flex;
}

.MainMenuCon6 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.MainMenuCon7 {
  align-self: stretch;
  align-items: flex-end;
  gap: 12px;
  padding: 0 6px;
  display: flex;
}

.MainMenuOptionText {
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}

.MainMenuOptionSeperator {
  background: var(--app-gray-soft, #e0e0e0);
  align-self: stretch;
  height: 2px;
}
/*# sourceMappingURL=index.1fe22030.css.map */
